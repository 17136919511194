import React from "react";
import styled, { css } from "styled-components";
import { Link } from "components/Link";

interface Props {
  className?: string;
  title: string;
  selectedValue: string;
  list: { value: any; url: string }[];
  type?: string;
}

function FilterPanel({ className, title, selectedValue, list, type }: Props) {
  return (
    <FilterPanelContainer className={className}>
      <Title>{title}</Title>
      <ListContainer>
        {list.map(el => (
          <ListElement key={el.value} isSelected={el.value === selectedValue}>
            <Link to={el.value === selectedValue ? "/news/" : el.url}>
              <Bullet isSelected={el.value === selectedValue} />
              {type === "time" ? `${el.value} min` : el.value}
            </Link>
          </ListElement>
        ))}
      </ListContainer>
    </FilterPanelContainer>
  );
}

const FilterPanelContainer = styled.div`
  padding: 40px 30px;
  box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 40px;
  box-sizing: border-box;
`;

const Title = styled.h3`
  font-weight: 700;
  font-size: 24px;
  font-family: ${({ theme }) => theme.fonts.title};
  padding-bottom: 15px;
  letter-spacing: 0.04em;
  line-height: 1.6em;
  color: ${({ theme }) => theme.mainColor};
  @media (max-width: 750px) {
    font-size: 18px;
  }
`;

const ListContainer = styled.ul``;

const ListElement = styled.li<{ isSelected: boolean }>`
  display: flex;
  align-items: center;
  padding: 12px 0;
  font-size: 14px;
  a {
    text-decoration: none;
    color: #333;
    display: flex;
    &:hover {
      color: ${({ theme }) => theme.mainColor};
      cursor: pointer;
    }
  }
  ${({ isSelected }) =>
    isSelected &&
    css`
      a {
        color: ${({ theme }) => theme.mainColor};
      }
    `};
`;

const Bullet = styled.div<{ isSelected: boolean }>`
  height: 10px;
  width: 10px;
  border: 2px solid #eee;
  border-radius: 50%;
  margin-right: 10px;
  ${({ theme, isSelected }) =>
    isSelected &&
    css`
      border: 2px solid ${theme.mainColor};
      background-color: ${theme.mainColor};
    `};
`;

export default styled(FilterPanel)``;
