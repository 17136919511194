import FilterPanel from "components/FilterPanel";
import { Link } from "components/Link";
import NewsletterForm from "components/Newsletter";
import Page from "components/Page";
import PostPreview from "components/PostPreview";
import {
  Hero,
  HeroImageContainer,
  HeroImg,
  HeroMessageContainer,
  HeroSubtitle,
  HeroTitle,
  Separator
} from "components/UI";
import Wrapper from "components/Wrapper";
import { graphql } from "gatsby";
import React from "react";
import styled from "styled-components";
import { PageLocale } from "types";

interface Props {
  data: {
    page: {
      fields: {
        name: string;
        locales: {
          language: string;
          path: string;
          title: string;
          seo: {
            description: string;
            image: string;
          };
          hero: {
            title: string;
            subtitle: string;
            image: string;
          };
        }[];
      };
    };
  };
  pageContext: {
    locale: string;
    name: string;
    prevPage: string;
    nextPage: string;
    filterType: string;
    selectedFilter: { value: string; url: string };
    currentPageIndex: number;
    numOfPages: number;
    posts: {
      node: {
        id: string;
        excerpt: string;

        html: string;
        fields: {
          slug: string;
        };
        frontmatter: {
          title: string;
          templateKey: string;
          date: string;
          description: string;
          image: { publicURL: string } | null;
          tags: string[];
        };
      };
    }[];
    allTags: { value: string; url: string }[];
  };
}

const NewsPage = ({ data, pageContext }: Props) => {
  const allPosts = pageContext.posts;
  const allTags = pageContext.allTags;
  const selectedFilter = pageContext.selectedFilter;

  const currentPage = data.page.fields.locales.find(
    locale => locale.language === pageContext.locale
  )!;
  const { currentPageIndex, numOfPages, prevPage, nextPage } = pageContext;
  const isFirst = currentPageIndex === 1;

  const isLast = currentPageIndex === numOfPages;

  return (
    <Page
      title={currentPage.title}
      description={currentPage.seo.description}
      localeCode={pageContext.locale}
      image={currentPage.seo.image}
      pageLocales={data.page.fields.locales.map(
        (locale: any): PageLocale => ({
          code: locale.language,
          url: selectedFilter.url ? selectedFilter.url : locale.path
        })
      )}
    >
      <Hero small>
        <HeroImageContainer>
          <HeroImg src={currentPage.hero.image} />
        </HeroImageContainer>
        <Wrapper>
          <HeroMessageContainer>
            <HeroTitle
              dangerouslySetInnerHTML={{ __html: currentPage.hero.title }}
            />
            <Separator light />
            <HeroSubtitle>{currentPage.hero.subtitle}</HeroSubtitle>
          </HeroMessageContainer>
        </Wrapper>
      </Hero>
      <Wrapper>
        <NewsSection>
          <PostsList>
            {allPosts &&
              allPosts.map(({ node: post }) => (
                <PostPreview key={post.id} post={post} />
              ))}
            <Navigation>
              {!isLast && (
                <Link to={nextPage} rel="next">
                  Articoli meno recenti
                </Link>
              )}
              {!isFirst && (
                <Link to={prevPage} rel="prev">
                  Articoli più recenti
                </Link>
              )}
            </Navigation>
          </PostsList>

          <PostsFilters>
            <FilterPanel
              title="Categoria"
              selectedValue={selectedFilter.value}
              list={allTags}
            />
          </PostsFilters>
        </NewsSection>
      </Wrapper>
      <NewsletterForm listId="98e7e97d2a" checkbox />
    </Page>
  );
};

export const query = graphql`
  query NewsPageQuery($name: String!) {
    page: pagesJson(fields: { name: { eq: $name } }) {
      fields {
        name
        locales {
          language
          path
          title
          seo {
            description
            image
          }
          hero {
            title
            subtitle
            image
          }
        }
      }
    }
  }
`;

const NewsSection = styled.div`
  display: flex;
  flex-direction: row;
  padding: 40px 0;
  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;

const PostsList = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 320px);
  padding-right: 40px;
  box-sizing: border-box;
  @media (max-width: 1000px) {
    width: 100%;
    padding-right: 0;
  }
`;

const PostsFilters = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 320px;
  width: 360px;
  @media (max-width: 1000px) {
    width: calc(100% + 40px);
    margin-left: -20px;
    flex-direction: row;
    ${FilterPanel} {
      width: 50%;
      margin: 20px;
    }
  }
  @media (max-width: 750px) {
    flex-direction: column;
    width: 100%;
    margin-left: 0;
    ${FilterPanel} {
      width: 100%;
      margin: 0;
      margin-bottom: 40px;
    }
  }
`;

const Navigation = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  padding-bottom: 80px;
  [rel="next"],
  [rel="prev"] {
    text-decoration: none;
    transition: all 0.3s;
    margin-top: 20px;
    width: 100%;
    transition: 0.4s;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 0.1em;
    color: ${({ theme }) => theme.mainColor};
    @media (max-width: 500px) {
      visibility: hidden;
      width: 160px;
    }
    &:hover {
      cursor: pointer;
      &::after {
        margin-left: 10px;
        margin-right: -10px;
        color: ${({ theme }) => theme.mainColorDark};
      }
      &::before {
        margin-right: 10px;
        margin-left: -10px;
        color: ${({ theme }) => theme.mainColorDark};
      }
    }
  }
  [rel="prev"] {
    text-align: right;
    &::after {
      visibility: visible;
      content: "→";
      display: inline-block;
      font-size: 14px;
      padding-left: 5px;
      transition: 0.4s;
      font-family: system-ui;
      color: ${({ theme }) => theme.mainColor};
      @media (max-width: 500px) {
        font-size: 13px;
        content: "PIÙ RECENTI →";
      }
    }
  }
  [rel="next"] {
    &::before {
      visibility: visible;
      content: "←";
      display: inline-block;
      font-size: 14px;
      padding-right: 5px;
      transition: 0.4s;
      font-family: system-ui;
      color: ${({ theme }) => theme.mainColor};
      @media (max-width: 500px) {
        display: none;
      }
    }
    &::after {
      @media (max-width: 500px) {
        visibility: visible;

        display: inline-block;
        font-size: 14px;
        padding-right: 5px;
        transition: 0.4s;
        font-family: system-ui;
        color: ${({ theme }) => theme.mainColor};

        font-size: 13px;
        content: "← MENO RECENTI";
      }
    }
  }
`;

export default NewsPage;
